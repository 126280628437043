// ===== Mixins & Functions =====
// Scale Size According to Width
@function scaleValue($min, $max) {
    $viewportRange: 1400px - 350px;
    $contentSizeRange: $max - $min;
    $idealSize: calc($min + (100vw * $contentSizeRange / $viewportRange));
    @return clamp($min, $idealSize, $max);
}

// Width & Height
@mixin widthHeight($width, $height) {
    width: $width;
    height: $height;
}

// Flexbox
@mixin displayFlex($align, $justify, $dir) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $dir;
}

// Grid
@mixin displayGrid($column, $align, $justify) {
    display: grid;
    grid-template-columns: $column;
    align-items: $align;
    justify-content: $justify;
}

// Media Query
@mixin min($minWidth) {
    @media (min-width: $minWidth) {
        @content;
    }
}

@mixin max($maxWidth) {
    @media (max-width: $maxWidth) {
        @content;
    }
}

@mixin Btn {
    gap: 8px;
    width: fit-content;
    padding-inline: 40px;
    border-radius: 48px !important;
    min-height: scaleValue(36px, 55px);

    cursor: pointer;
    font-size: scaleValue(14px, 16px) !important;
    line-height: scaleValue(19px, 19px) !important;
    position: relative;
    isolation: isolate;

    p {
        font-size: scaleValue(16px, 16px);
        font-weight: var(--fw_medium);
        line-height: scaleValue(19px, 19px);
        color: var(--clr_text_secondary);
    }

    &:hover {
        background: var(--clr_bg_classPoint);
        color: var(--clr_text_secondary);
    }
}
