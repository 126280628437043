@use './index.scss' as *;

:root {
    // ========== Colour ==========
    --clr_white: #ffffff;
    --clr_white_alpha50: hsla(0, 0%, 100%, 0.5);
    --clr_offWhite: #f6f7fe;

    --clr_black: #000000;
    --clr_black_fade: #222222;
    --clr_black_fade_02: #333333;

    // Text
    --clr_text_classPoint_color: #6378ff;
    --clr_text_primary: #1a1a1a;
    --clr_text_secondary: #ffffff;

    --clr_deep_blue_text: #0f1c42;

    --clr_text_neutral: #5a5a5a;
    --clr_text_neutral_light: #818181;
    --clr_text_neutral_50: rgba(90, 90, 90, 0.5);

    --clr_text_error: #dc362e;
    --clr_text_error_dark: #b3261e;
    --clr_text_success: #42cf63;
    --clr_text_offer: #ff9c24;

    // Background
    --clr_bg_primary: #ffffff;
    --clr_bg_secondary: #222222;
    --clr_bg_blue_extraLight: #edf0ff;

    --clr_bg_light: #f6f7ff;
    --clr_bg_shopee: #f1592a;

    --clr_bg_success: #42cf63;
    --clr_bg_success_extraLight: #d9f5e0;

    --clr_discount_bg: #ffcd4b;
    --clr_bg_error_light: #fad2d3;

    --background_black_07: rgba(0, 0, 0, 0.7);

    // backGround_grad
    --clr_bg_classPoint: #6378ff;
    --clr_bg_classPoint_02: #dbe0ff;
    --clr_bg_grad_01: linear-gradient(132.29deg, #7083ff 29.47%, #5d63f7 94.59%);
    --clr_bg_grad_02: linear-gradient(75.66deg, #6378ff 17.84%, #8652f4 69.88%);
    --clr_bg_grad_03: linear-gradient(266.85deg, #fc7dff -16.2%, #6386ff 117.07%);

    // Overlay

    // ripple
    --clr_ripple: rgba(99, 120, 255, 0.6);

    // border
    --clr_border_classPoint: #6378ff;
    --clr_border_classPoint_02: #9daafb;
    --clr_border_neutral: #a7a7a7;
    --clr_border_neutral_light: #cecece;
    --clr_border_success: #42cf63;

    // ========== Font Family ==========
    --font_primary: 'Poppins', sans-serif;
    --font_secondary: 'DM Sans', sans-serif;

    // ========== Font Weight ==========
    --fw_light: 300;
    --fw_regular: 400;
    --fw_medium: 500;
    --fw_semiBold: 600;
    --fw_bold: 700;
    --fw_extraBold: 800;
    --fw_black: 900;
}

@media (prefers-color-scheme: dark) {
    :root {
        // ========== Colour ==========
        --clr_white: #ffffff;
        --clr_white_alpha50: hsla(0, 0%, 100%, 0.5);
        --clr_offWhite: #f6f7fe;

        --clr_black: #000000;
        --clr_black_fade: #222222;
        --clr_black_fade_02: #333333;

        // Text
        --clr_text_classPoint_color: #6378ff;
        --clr_text_primary: #1a1a1a;
        --clr_text_secondary: #ffffff;

        --clr_deep_blue_text: #0f1c42;

        --clr_text_neutral: #5a5a5a;
        --clr_text_neutral_light: #818181;
        --clr_text_neutral_50: rgba(90, 90, 90, 0.5);

        --clr_text_error: #dc362e;
        --clr_text_error_dark: #b3261e;
        --clr_text_success: #42cf63;
        --clr_text_offer: #ff9c24;

        // Background
        --clr_bg_primary: #ffffff;
        --clr_bg_secondary: #222222;
        --clr_bg_blue_extraLight: #edf0ff;

        --clr_bg_light: #f6f7ff;
        --clr_bg_shopee: #f1592a;

        --clr_bg_success: #42cf63;
        --clr_bg_success_extraLight: #d9f5e0;

        --clr_discount_bg: #ffcd4b;
        --clr_bg_error_light: #fad2d3;

        --background_black_07: rgba(0, 0, 0, 0.7);

        // backGround_grad
        --clr_bg_classPoint: #6378ff;
        --clr_bg_classPoint_02: #dbe0ff;
        --clr_bg_grad_01: linear-gradient(132.29deg, #7083ff 29.47%, #5d63f7 94.59%);
        --clr_bg_grad_02: linear-gradient(75.66deg, #6378ff 17.84%, #8652f4 69.88%);
        --clr_bg_grad_03: linear-gradient(266.85deg, #fc7dff -16.2%, #6386ff 117.07%);

        // Overlay

        // ripple
        --clr_ripple: rgba(99, 120, 255, 0.6);

        // border
        --clr_border_classPoint: #6378ff;
        --clr_border_classPoint_02: #9daafb;
        --clr_border_neutral: #a7a7a7;
        --clr_border_neutral_light: #cecece;
        --clr_border_success: #42cf63;
    }
}
